<template>
  <div 
      class="messaggio"
      class:di-errore={di_errore}
      class:visualizzato={visualizzato}
      bind:this={elemento}>
    <span>
      <slot>{@html testo}</slot>
    </span>
    <Bottone
        piccolo={true}
        variante="secondario-invertito"
        on:click={nascondi_messaggio}>
      <Icona tipo="chiudi"/>
    </Bottone>
  </div>
</template>

<style>
  :global(div.messaggio) {
    display:      flex;
    align-items:  center;
    position:     fixed;
    bottom:       -101%;
    padding:      14px;
    z-index:      11;
    background:   #ffffff;
    box-shadow:   -10px 10px 15px 5px var(--colore-ombreggiatura);
    left:         50%;
    transform:    translateX(-50%);
    transition:   bottom 0.7s ease-in-out;
  }

  :global(div.messaggio span) {
    margin-right: 14px;
  }

  :global(div.messaggio.visualizzato) {
    bottom:       14px;
  }
  :global(div.messaggio.di-errore) {
    color:        var(--colore-rosso);
  }
</style>

<script>
  import Icona from "@/base/componenti/Icona.svelte"
  import Bottone from "@/base/componenti/Bottone.svelte"
  import { beforeUpdate } from "svelte"

  export let visualizzato = false
  export let di_errore = false
  export let testo = ""
  export let senza_scadenza = false

  let scadenza, elemento
  
  // Pianifica la chiusura messaggio.
  function pianifica_nascondi_messaggio() {
    if (senza_scadenza) return
    if (scadenza != null) clearInterval(scadenza)
    scadenza = setTimeout(nascondi_messaggio, 3700)
  }
  
  // Chiude il messaggio.
  function nascondi_messaggio() {
    clearInterval(scadenza)
    visualizzato = false

    if (elemento == null) return
      elemento.classList.remove("visualizzato")
  }
  
  // Quando cambia la visualizzazione, nascondi il messaggio
  // dopo un certo tempo.
  beforeUpdate(pianifica_nascondi_messaggio)
</script>

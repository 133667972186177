// Svuby
import "@/base/sorgenti/svuby"

// App
import App from "@/best_western/sorgenti/App.svelte"

const app = new App({
	target: document.body
})

export default app
